<template>
    <div class="content-wrapper">
        <!--Хлебные крошки-->
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <!---->
        <div class="content">
            <SpinnerLoader v-if="loading" />
            <div v-else>
                <v-row class="ma-0" justify="space-between" align="center">
                    <h1 class="dashboard">Настройки проекта</h1>
                    <v-btn
                        elevation="0"
                        color="main"
                        class="white--text"
                        @click="saveSettings"
                        :disabled="!checkRole(project.id, 'project:settings:save')"
                        :loading="saveSettingsLoading"
                    >
                        Сохранить
                    </v-btn>
                </v-row>
                <div
                    class="settings-group"
                    v-for="settingGroup in availableSettingsList"
                    :key="settingGroup.id"
                >
                    <v-row class="ma-0 mt-6"><h2 class="dashboard">{{ settingGroup.title }}</h2> <v-icon class="ml-3" v-if="settingGroup.title === 'Чат с клиентом'">{{ '$vuetify.icons.chat' }}</v-icon></v-row>
                    <div class="my-6" v-for="setting in settingGroup.list" :key="setting.id">
                        <div class="setting">
                            <span class="setting-label mr-12">{{ setting.label }}</span>
                            <Select
                                :disabled="
                                    setting.disabled || !checkRole(project.id, 'project:settings:save')
                                "
                                :class="{
                                    'input-disabled':
                                        setting.disabled || !checkRole(project.id, 'project:settings:save'),
                                }"
                                dense
                                class="setting-input"
                                hide-details
                                :selectionMaxLength="100"
                                :filled="false"
                                v-model="settings[setting.id]"
                                item-text="label"
                                item-value="value"
                                :multiple="setting.multiple"
                                :items="setting.values.filter(el => !el.hide)"
                            ></Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import Breadcrumbs from '../components/Breadcrumbs.vue'
import Select from '../components/inputs/Select.vue'
import { getCurrentSettings, getSettingsList, saveSettings } from '@/api/projects'
import * as _ from 'lodash'
import SpinnerLoader from '../components/SpinnerLoader.vue'
import { CONNECTOR_TYPES } from '../vars/general'
import { checkRole } from '../helpers/roles'
const hasGcb2Connector = project =>
    Boolean(project.connectors.find(el => el.connectorType === CONNECTOR_TYPES.GCB2))
export default {
    name: 'Project',
    components: { Breadcrumbs, Select, SpinnerLoader },
    data: () => ({
        settings: {},
        startSettings: {},
        loading: true,
        saveSettingsLoading: false,
    }),

    computed: {
        ...mapState(['roles']),
        ...mapGetters(['projectById']),
        project() {
            const projectId = this.$router.currentRoute.params.id
            return this.$store.getters.projectById(projectId)
        },
        breadcrumbs() {
            return [
                {
                    text: 'ВАШИ ПРОЕКТЫ',
                    to: '/projects',
                },
                {
                    text: 'HR платформа Точек красоты',
                    to: `/project/${this.project.id}`,
                },
                {
                    text: 'НАСТРОЙКИ',
                    to: '',
                    disabled: true,
                },
            ]
        },
        settingsList() {
            return [
                {
                    id: 'overview',
                    title: 'Вкладка «Обзор»',
                    list: [
                        {
                            id: 'overview/hideTags',
                            label: 'Скрыть теги активаций',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                    ],
                },
                {
                    id: 'wa',
                    title: 'Чат с клиентом',
                    list: [
                        {
                            id: 'wa/hideIcon',
                            label: 'Скрыть значок',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                        /*  {
                            id: 'wa/textType',
                            label: 'Текст скрипта',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Из активации', value: 'activationScript' },
                                { label: 'Пустой', value: 'blank' },
                            ],
                        }, */
                        /* {
                            id: 'wa/doubleRedirect',
                            label: 'Исправить то, что WhatsApp открывается с пустым диалогом, без скрипта',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Нет', value: null },
                                { label: 'Да', value: true },
                            ],
                        }, */
                        {
                            id: 'wa/template',
                            label: 'Ссылка ведет на:',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                {
                                    label: 'Веб-версию',
                                    value: null,
                                },
                                {
                                    label: 'Приложение',
                                    value: 'whatsapp://send?phone={{phone}}&text={{text}}',
                                },
                            ],
                        },
                        {
                            id: 'wa/copyToBuffer',
                            label: 'Копировать текст активации в буфер обмена:',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                {
                                    label: 'Да',
                                    value: true,
                                },
                                {
                                    label: 'Нет',
                                    value: null,
                                },
                            ],
                        },
                        /*                        {
                            id: 'wa/enableBot',
                            label: 'Использовать бот от Data Heroes',
                            type: 'select',
                            values: [
                                {
                                    label: 'Да',
                                    value: true,
                                },
                                {
                                    label: 'Нет',
                                    value: null,
                                },
                            ],
                        }, */
                    ],
                },
                {
                    id: 'tasklist',
                    title: 'Список задач',
                    list: [
                        {
                            id: 'tasklist/hideLoyality',
                            label: 'Скрывать колонку «Лояльность»',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                        {
                            id: 'tasklist/showLastVisitedColumn',
                            label: 'Показывать колонку «Последнее посещение»',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                        {
                            id: 'tasklist/enablePriority',
                            label: 'В списке задач один клиент только в одной активации',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                        {
                            id: 'tasklist/showActionModalOnContact',
                            label:
                                'Включить возможность оставить тег или комментарий при нажатии "Связались"',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Да', value: true },
                                { label: 'Нет', value: null },
                            ],
                        },
                        {
                            id: 'tasklist/actionModalValidation',
                            label:
                                'Какие поля обязательно заполнять при коммуникациях с клиентами, откладываниях и добавлениях в список «Не напоминаем»',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Теги или комментарий', value: null },
                                { label: 'Теги и комментарий', value: 'tagsAndComment' },
                                { label: 'Никакие', value: 'disabled' },
                            ],
                        },
                    ],
                },
                {
                    id: 'analytics',
                    title: 'Аналитика',
                    list: [
                        {
                            id: 'analytics/elements',
                            label: 'Отображаемые элементы',
                            type: 'select',
                            multiple: true,
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: 'Фильтр «Телефон»', value: 'filter:phone' },
                                { label: 'Фильтр «Клиент»', value: 'filter:client' },
                                { label: 'Фильтр «Активация»', value: 'filter:activation' },
                                { label: 'Фильтр «Статус»', value: 'filter:extendedStatus' },
                                { label: 'Фильтр «Пользователь»', value: 'filter:user' },
                                {
                                    label: 'Фильтр «Филиал атрибуции коммуникации»',
                                    value: 'filter:projectAttribution',
                                },
                                {
                                    label: 'Фильтр «Филиал коммуникации»',
                                    value: 'filter:projectCommunication',
                                },
                                { label: 'Таблица «Список коммуникаций»', value: 'table:communicationList' },
                                {
                                    label: 'Таблица «Статистика в разрезе сотрудников»',
                                    value: 'table:statisticsStaff',
                                },
                                {
                                    label: 'Таблица «Статистика в разрезе активаций»',
                                    value: 'table:statisticsActivation',
                                },
                                {
                                    label: 'Таблица «Cтатистика в разрезе способа связи»',
                                    value: 'table:statisticsCommType',
                                },
                                {
                                    label: 'Таблица «Статистика в разрезе филиалов коммуникации»',
                                    value: 'table:statisticsProjectCommunication',
                                },
                                {
                                    label: 'Таблица «Статистика в разрезе атрибуции клиентов»',
                                    value: 'table:statisticsProjectAtribution',
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'activation',
                    title: 'Активации',
                    list: [
                        {
                            id: 'activation/maxCount',
                            label: 'Максимальное количество включенных активаций',
                            type: 'select',
                            showCondition: hasGcb2Connector,
                            values: [
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                                { label: '6', value: 6 },
                                { label: '7', value: 7 },
                                { label: '8', value: 8 },
                                { label: '9', value: 9 },
                                { label: '10', value: 10 },
                            ],
                        },
                    ],
                },
                {
                    id: 'testFeature',
                    title: 'Тестирование новой функциональности',
                    list: [],
                },
            ]
        },
        availableSettingsList() {
            return this.settingsList
                .map(settingsGroup =>
                    Object.assign({}, settingsGroup, {
                        list: settingsGroup.list.filter(el =>
                            el.showCondition ? el.showCondition(this.project) : true
                        ),
                    })
                )
                .filter(settingsGroup => settingsGroup.list.length > 0)
        },
    },
    watch: {},
    methods: {
        checkRole,
        async saveSettings() {
            try {
                this.saveSettingsLoading = true
                const changedSettingsIds = _.reduce(
                    this.startSettings,
                    (result, value, key) =>
                        _.isEqual(value, this.settings[key]) ? result : result.concat(key),
                    []
                )
                const changedSettings = _.pickBy(this.settings, (value, key) =>
                    changedSettingsIds.includes(key)
                )
                await saveSettings({
                    projectId: this.project.id,
                    settings: changedSettings,
                })
                await this.$store.dispatch('project/fetchProject', this.project.id)
                this.saveSettingsLoading = false
                this.$router.push(`/project/${this.project.id}`)
            } catch (err) {
                console.error(err)
                this.saveSettingsLoading = false
                this.$store.dispatch('callNotify', 'Ошибка при сохранении настроек')
            }
        },
        setSetting(id, value) {
            this.settings = Object.assign({}, this.settings, {
                [id]: value,
            })
        },
        setChangedSettingId(settingId) {
            this.changedSettingsIds.push(settingId)
        },
    },
    async created() {
        this.loading = true
        const settings = (await getCurrentSettings(this.project.id)).data
        this.startSettings = _.cloneDeep(settings)
        this.settings = settings
        this.loading = false
    },
    mounted() {},
}
</script>
<style lang="sass" scoped>
.setting
    display: flex
    justify-content: space-between
    align-content: center
.setting-label
    width: 500px
    display: inline-flex
    align-items: flex-end
.setting-input
    width: 600px
.input-disabled
    opacity: 0.5
</style>
